"use strict";

require('smoothscroll-polyfill').polyfill();

const defaults = {
    selectors: {
        arrowToTop: '[data-el="scroller"]',
    },
    settings: {
        scrollDuration: 400,
    }
};
export default function bindAllScrollTop() {
    let links = document.querySelectorAll(defaults.selectors.arrowToTop);

    Array.from(links).forEach(link => {

        if (!link) return false;

        link.addEventListener('click', function(event) {
            event.preventDefault();
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        })
    })
}
