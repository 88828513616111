import {hasClass, addClass, removeClass, slideDown, slideUp} from "./helpers";

var accordionElements = document.querySelectorAll('[data-el="accordions"]');
var accordionXlElements = document.querySelectorAll('[data-el="accordions-contactsxl"]');

export function initAccordions() {

    accordionElements.forEach(function(accordion) {

        let accordionTriggers = accordion.querySelectorAll('[data-accordion-trigger]');
        let accordionContents = accordion.querySelectorAll('[data-accordion-content]');

        accordionTriggers.forEach(function(trigger, mainId) {
            trigger.addEventListener('click', function() {
                if(!hasClass(this.parentNode, 'is-active')) {

                    accordionTriggers.forEach(function(trigger, triggerId) {
                        triggerId !== mainId ? removeClass(trigger.parentNode, 'is-active') : addClass(trigger.parentNode, 'is-active');
                    })

                    accordionContents.forEach(function(content, contentId) {
                        contentId !== mainId ? slideUp(content, 250) : slideDown(content, 250);
                    })

                } else {

                    accordionTriggers.forEach(function(trigger) {
                        removeClass(trigger.parentNode, 'is-active');
                    })

                    accordionContents.forEach(function(content) {
                        slideUp(content, 250);
                    })

                }
                setTimeout(function () {
                    accordionTriggers.forEach(function(trigger) {
                        if(hasClass(trigger.parentNode, 'is-active')) {
                            if(trigger.nextElementSibling) {
                                let contentHeight = trigger.nextElementSibling.scrollHeight;
                                let screenHeight = document.documentElement.clientHeight;

                                if(contentHeight > (screenHeight / 2)) {
                                    let offseting = Math.round(trigger.getBoundingClientRect().top);

                                    window.scrollTo({
                                        top: +offseting + window.scrollY - 280,
                                        behavior: "smooth"
                                    });
                                }
                            }

                        }
                    })
                }, 350);



            })
        })
    })

    accordionXlElements.forEach(function(accordion) {

        let accordionTriggers = accordion.querySelectorAll('[data-accordion-trigger]');
        let accordionContents = accordion.querySelectorAll('[data-accordion-content]');

        accordionTriggers.forEach(function(trigger, mainId) {
            trigger.addEventListener('click', function() {
                if(!hasClass(this.parentNode, 'is-active')) {

                    accordionTriggers.forEach(function(trigger, triggerId) {
                        triggerId !== mainId ? removeClass(trigger.parentNode, 'is-active') : addClass(trigger.parentNode, 'is-active');
                    })

                    accordionContents.forEach(function(content, contentId) {
                        contentId !== mainId ? slideUp(content, 250) : slideDown(content, 250);
                    })

                } else {

                    accordionTriggers.forEach(function(trigger) {
                        removeClass(trigger.parentNode, 'is-active');
                    })

                    accordionContents.forEach(function(content) {
                        slideUp(content, 250);
                    })

                }

            })
        })
    })

}
