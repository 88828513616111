import {addStyle, removeClass, slideDown} from "./helpers";

// const loadMore = document.querySelector('[data-load="news"]');
const loadMoreButtons = document.querySelectorAll('[data-load="news"]');

export function initNewsLazyLoad() {

    loadMoreButtons.forEach(function (loadMore) {

        let hiddenItems = loadMore.parentNode.parentNode.querySelectorAll('.tabsevents-item.is-hidden, .newslist-list li.is-hidden');
		console.log(hiddenItems);

        if(hiddenItems.length > 0) {
            addStyle(loadMore, 'display', 'inline-flex');
        }

        loadMore.addEventListener('click', function() {

            let hiddenItems = loadMore.parentNode.parentNode.querySelectorAll('.tabsevents-item.is-hidden, .newslist-list li.is-hidden');
            let toReveal = [...hiddenItems].slice(0, 12);

            toReveal.forEach(function(item) {
                let image = item.querySelector('.tabsevents-image-holder img');
                if(image) {
                    image.src = image.dataset.src;
                }

                removeClass(item, 'is-hidden');
                slideDown(item, 300);
            })

            if(hiddenItems.length <= 12) {
                addStyle(loadMore, 'display', 'none');
            }

        })

    })

}
