"use strict";
import _merge from 'lodash/merge';
import {removeClass, addClass, hasClass, findAncestorByClass} from "../components/helpers";

const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
import config from './mapbox-maps-config';

const defaults = {
    selectors: {
        mapContainer: '.js-map-container',
        mapOuter: '.js-map-outer',
        mapHead: '.js-map-head',
        map: '.js-map',
        mapList: '.js-map-list',
        mapLegend: '.js-map-legend',
        mapLegendToggle: '.js-map-legend-toggle',
        mapDataContainer: '.js-map-data',
        showInMap: '.js-show-in-map',
        mapContent: '.js-map-content',
        closeButtons: ".js-close-info",
    },
    classes: {
        activeClass: 'is-active',
        initClass: 'is-map-initialized',
        legendOpenClass: 'is-legend-open'
    },
    api: {
        path: '/api/listings/map'
    },
    defaultImage: 'pins/amblem.svg',
    tagGroupId: 37,
    defaultZoom: 10,
    defaultZoomSingle: 11,
    package_id_min: 1,
    defaultCenter: {
        lng: 10.951271,
        lat: 48.753049
    },
};


const state = {
    mapContainers: null,
    maps: []
};

class VbMbMap {
    constructor(mapContainer, mapData, mapOptions) {
        this.mapContainer = mapContainer;
        this.options = mapOptions;
        this.showAddressInPopup = mapContainer.dataset.popupShowAddress;
        this.imageBasePath = mapContainer.dataset.imageBasePath;
        this.shouldFitBounds = mapContainer.dataset.fitBounds === "true";
        this.mapEl = this.mapContainer.querySelector(this.options.selectors.map);
        this.zoomFactor = this.options.defaultZoom;
        this.updateZoomClass();
        this.bindCloseButton();
        this.markers = [];
        this.mapData = {};

        if (!mapData) {
            return false;
        } else {
            this.updateMapData(mapData)
                .then(data => this.buildMap(data))
        }
        this.checkExternalTrigger();
        this.mapContainer.classList.add(this.options.classes.initClass);
    }

    updateMapData(mapData) {
        return new Promise(resolve => {
            this.mapData = this.buildGeoJson(mapData);
            resolve(this.mapData);
        });
    }

    bindCloseButton() {
        let allCloseButtons =  document.querySelectorAll(this.options.selectors.closeButtons);

        Array.from(allCloseButtons).forEach(closeButton => {
            closeButton.addEventListener("click", event => {
                event.preventDefault();

                this.closeInfo();
            })
        })
    }

    buildGeoJson(data) {
        let geoJson = {
            type: "FeatureCollection",
            features: [],
        };

        let tempFeatures = [];

        data.places.forEach(place => {
            let newFeature = {
                type: "Feature",
                properties: {
//                    place_id: place.id,
                    marker_id: place.marker_id,
                    icon: place.icon,
                    title: place.display_name|| place.name,
                    description: place.description,
                    contact: place.contact,

                },
                geometry: place.geometry

            };

            tempFeatures.push(newFeature);
        });

        geoJson.features = tempFeatures;
        geoJson = JSON.parse(JSON.stringify(geoJson));
        return geoJson;
    }

    buildMap(data) {
        mapboxgl.accessToken = config.mapbox.accessToken;

        this.map = new mapboxgl.Map({
            container: this.mapEl, // container id
            style: config.mapbox.style, // stylesheet location
            center: [this.options.defaultCenter.lng, this.options.defaultCenter.lat], // starting position [lng, lat]
            zoom: this.options.defaultZoom // starting zoom
        });

        this.map.scrollZoom.disable();

        this.map.on('load', (e) => {
            this.map.addSource('places', {
                type: 'geojson',
                data: this.mapData
            });

            this.addControls();
            this.addMarkers(data);
        });

        this.map.on('zoom', (e) => {
            this.zoomFactor = this.map.getZoom();
            this.updateZoomClass(this.zoomFactor);
        })
    }

    updateZoomClass(zoomfactor) {
        zoomfactor = zoomfactor || this.zoomFactor;
        this.mapContainer.dataset.zoomFactor = parseInt(zoomfactor);
    }

    addMarkers(data) {
        let bounds = new mapboxgl.LngLatBounds();

		data.features.forEach((marker, i) => {

            // create a HTML element for each feature
            let el = document.createElement('div');
            el.className = 'marker';
            el.classList.add('marker-' + marker.properties['icon']);
            el.dataset.id = marker.properties.marker_id;


			// const el = document.createElement('div');
			// const width = 100;
			// const height = 64;
			// el.className = 'marker';
			// el.classList.add('marker-' + marker.properties['icon']);
			// el.dataset.id = marker.properties.marker_id;
			// el.style.backgroundImage = `url(assets/img/locator-${marker.properties.imageId}/${width}/${height})`;
			// el.style.width = `${width}px`;
			// el.style.height = `${height}px`;
			// el.style.backgroundSize = '100%';



            this.markers.push(el);

            if(!marker.geometry) {
                // then what?
            }

            if(this.shouldFitBounds) {
                bounds.extend(marker.geometry.coordinates);
            }

            // make a marker for each feature and add to the map
            new mapboxgl.Marker(el, { offset: [0, -35] })
                .setLngLat(marker.geometry.coordinates)
                .addTo(this.map);

            el.addEventListener('click', (e) => {
				this.closePopup();
				let activeItem = document.getElementsByClassName('active');
                // 1. Fly to the point
                // flyToStore(marker);
                this.flyToLocation(marker);
                // 2. Close all other popups and display popup for clicked store
                // this.createPopUp(marker);
                this.showInfo(marker, el);
                // 3. Highlight listing in sidebar (and remove highlight for all other listings)
                e.stopPropagation();
            });


			// Array of class names to check
			const classesToCheck = ['style-wohnstifte', 'style-dichterviertel', 'style-uhlenhorst', 'style-raadt', 'style-intensivpflege'];
			const hasClass = classesToCheck.some(className => document.body.classList.contains(className));

			if (hasClass) {
				el.addEventListener('mouseenter', (e) => {
					this.closePopup();
					this.createInfoBox(marker);

					let activeItem = document.querySelector('.marker.is-active');
					if(activeItem) {
						removeClass(activeItem, "is-active");
					}
					addClass(el,'is-active');
					e.stopPropagation();
				});

				el.addEventListener('mouseleave', (e) => {
					// this.closePopup();
				});
			}

			if(data.features.length === 1) {
				// el.addEventListener('click', (e) => {
				// 	let activeItem = document.getElementsByClassName('active');
					// 1. Fly to the point
					// flyToStore(marker);
				this.flyToLocation(marker);
					// 2. Close all other popups and display popup for clicked store
					// this.createPopUp(marker);
				this.showInfo(marker, el);
					// 3. Highlight listing in sidebar (and remove highlight for all other listings)
					// e.stopPropagation();
				// });
			}
        });


        if(this.shouldFitBounds) {
            if (data.features.length > 1) {
                this.map.fitBounds(bounds, { padding: {top: 100, bottom:100, left: 100, right: 100}, linear: true, animate: false  });
            } else {
                this.map.setCenter(data.features[0].geometry.coordinates);
                this.map.setZoom(this.options.defaultZoomSingle);
            }
        }
    }

    addControls() {
        this.map.addControl(new mapboxgl.NavigationControl());

        this.map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        }), 'top-right');
    }

    flyToLocation(currentFeature) {
        const zoomZoom = this.zoomFactor > this.options.defaultZoomSingle ? this.zoomFactor : this.options.defaultZoomSingle;
        this.map.flyTo({
            center: currentFeature.geometry.coordinates,
            zoom: zoomZoom
        });
    }

	createPopUp(currentFeature) {
		this.closePopup();

		let popupHtml = '<h3 class="popup-headline">' + currentFeature.properties.title + '</h3>';

		if (currentFeature.properties['description']) {
			popupHtml = popupHtml +  '<div class="popup-content content"><p>' + currentFeature.properties.description + '</p></div>';
		}

		if (this.showAddressInPopup) {
			popupHtml = popupHtml +
				'<div class="popup-content address">' +
				// currentFeature.properties.contact +
				currentFeature.properties.contact +
				'</div>';
		} else if (currentFeature.properties['link'] && false ) {
			popupHtml = popupHtml + '<div class="popup-content content"><a class="popup-link btn-secondary" href="/listings/' + currentFeature.properties['link'] + '">mehr anzeigen</a></div>';
		} else if (currentFeature.properties['link-text'] && currentFeature.properties['link-url'] && false) {
			popupHtml = popupHtml + '<div class="popup-content content"><a class="popup-link btn-secondary" href="' + currentFeature.properties['link-url'] + '">' + currentFeature.properties['link-text'] +'</a></div>';
		}

		var popup = new mapboxgl.Popup({ closeOnClick: false, offset: [0, -32] })
			.setLngLat(currentFeature.geometry.coordinates)
			.setHTML(popupHtml)
			.setMaxWidth("none")
			.addTo(this.map);
	}

	createInfoBox(currentFeature) {
		this.closePopup();

		if(currentFeature.properties.title) {
			let popupHtml = '<h3 class="popup-headline">' + currentFeature.properties.title + '</h3>';

			var popup = new mapboxgl.Popup({ closeOnClick: false, offset: [0, -40], className: "infobox-popup"  })
				.setLngLat(currentFeature.geometry.coordinates)
				.setHTML(popupHtml)
				.setMaxWidth("none")
				.addTo(this.map);
		}
	}

    showInfo(currentFeature, element) {
        this.closeInfo();
        let markerID = currentFeature.properties.marker_id;
        let info = document.querySelector('#marker-' + markerID);
        addClass(info, "is-visible");
        let mapContent = document.querySelector(this.options.selectors.mapContent);
        addClass(mapContent, "has-events");
        // info.style.display = 'block';
        console.log(currentFeature);

        let activeItem = document.querySelector('.marker.is-active');
        if(activeItem) {
            removeClass(activeItem, "is-active");
        }
        addClass(element,'is-active');
    }

    closeInfo() {
        let openMarker = document.querySelector('.marker-content.is-visible');
        if(openMarker) {
            removeClass(openMarker, "is-visible");
        }
        let mapContent = document.querySelector(this.options.selectors.mapContent);
        removeClass(mapContent, "has-events");
        let activeItem = document.querySelector('.marker.is-active');
        if(activeItem) {
            removeClass(activeItem, "is-active");
        }
    }

    closePopup() {
        var popUps = document.getElementsByClassName('mapboxgl-popup');
        // Check if there is already a popup on the map and if so, remove it
        if (popUps[0]) popUps[0].remove();
    }

    checkExternalTrigger() {
        const triggers = document.querySelectorAll(this.options.selectors.showInMap);

        Array.from(triggers).forEach(trigger => {
            trigger.addEventListener("click", e => {

                this.closePopup();

                let target = trigger.dataset.action;

                let targetFeature = this.mapData.features.find(feature => {
                    return feature.properties.marker_id === target;
                });
                this.flyToLocation(targetFeature);
                this.createPopUp(targetFeature);
                var mapS = document.getElementById('map-section');
                window.scroll({ top: mapS.offsetTop - 100, left: 0, behavior: 'smooth' });
            })
        })
    }
}

export function initMaps (options) {

    let mapOptions = _merge(defaults, options);

    let mapContainers = document.querySelectorAll(defaults.selectors.mapContainer);

    if (mapContainers) {
        Array.from(mapContainers).forEach(mapContainer => {
            if(hasClass(mapContainer, mapOptions.classes.initClass)) {
                return ;
            }
            let mapDataContainer = mapContainer.querySelector(mapOptions.selectors.mapDataContainer);
            let mapData = mapDataContainer ? (mapDataContainer.text.trim().length > 0 ? JSON.parse(mapDataContainer.text) : false) : false;

            if(mapData) {
                new VbMbMap(mapContainer, mapData, mapOptions);
            } else {
                return false;

            }
        });

    }
}
