"use strict";

import 'es6-promise/auto';
require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();

import {initCookieNotice} from './components/vbcn-config';
import {
	initScrollingProgress,
	initHeroScrollEffect,
	initCharacterCounter,
	bindScrollToErrors,
	initContactEkmWomensclinic,
	initSearchClear
} from "./components/snippets";
import bindAllScrollTop from "./components/arrow-to-top";
import {initHeader, initOpenSearchPage, initSearch, initSidebar} from "./components/header";
import {
	initCertificateSliders,
	initverticalSliders,
	initHorizontalSliders,
	initSwiffySliders
} from "./components/sliders";
import {embedVideoLightbox, galleryLightbox, singleImageLightbox} from "./components/lightbox";
import {initAccordions} from "./components/accordions";
import {initMaps} from "./map-components/mapbox-maps";
import {initEventTabs} from "./components/event-tabs";
import {initNewsLazyLoad} from "./components/news-lazyload";
import {initContactInfos} from "./components/contact-infos";
import {initSiteSearch} from "./components/site-search";
import {am_autocomplete, am_autocomplete_state} from "./components/site-search";
import {initSocials} from "./components/socials";
import {initNewsList} from "./components/news-list";
import {initContactsList} from "./components/contactslist";
import {initWelcomeInfo} from "./components/welcome-info";

// Document is ready!
document.addEventListener('DOMContentLoaded', function() {

    initCookieNotice();
    initScrollingProgress();
    initHeroScrollEffect();
	bindScrollToErrors();
	bindAllScrollTop();

    initHeader();
    initSearch();
    initSiteSearch();
    initSidebar();

	initSwiffySliders();
    initCertificateSliders();
	initverticalSliders();
	initHorizontalSliders();
    initAccordions();
    initEventTabs();
    initNewsLazyLoad();
    initContactInfos();
    initCharacterCounter();
    initOpenSearchPage();
	initContactEkmWomensclinic();
	initSocials();
	initNewsList();
	initContactsList();

    embedVideoLightbox();
    singleImageLightbox();
    galleryLightbox();

    window.initMaps = initMaps;
    // window.am_autocomplete  = am_autocomplete;
    // window.am_autocomplete_state  = am_autocomplete_state;
});
