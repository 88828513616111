import init from "../vbcn";

const vbcnOptions = {
    notice: {
        ignoredPages: [
            '/no-data'
        ],
        ignoredHashes: [
            '#hide-my-stuff',
        ],
        backdropColor: 'red',
        centerMode: false,
        useBackdrop: false,
        analytics: {
            addInfo: true,
        }
    },
    texts: {
        "de": {
			accept_preset_ButtonText: "Alle akzeptieren",
            explanationText: "Nach Ihrer Zustimmung verwenden wir Cookies um die Anzeige zu optimieren. Zur Analyse der Zugriffe auf unsere Website verwenden wir Matomo. Außerdem binden wir Skripte von YouTube und Mapbox ein. Dabei können personenbezogene Daten an diese Anbieter übermittelt werden. Bitte klicken Sie <a href='/datenschutz'>hier</a>, um Informationen zum Datenschutz zu erhalten.",
            configuration_explanation_headline: "Was ist ein Cookie?",
            configuration_explanation_text: "Unter <strong>Cookie</strong> versteht man eine kleine <strong>Datei</strong>, beim Besuch von Internetseiten auf Deinem Computer oder Smartphone gespeichert wird. Besuchst Du die Website zu einem späteren Zeitpunkt wieder, wird der gespeicherte Cookie an den Webserver übertragen.",
        },
        "en": {
            explanationText: "On your consent cookies are set to optimize your experience on our website. \n\n" +
                "We use [[ Google Analytics / Matomo Analytics ]] to analyze the usage  of our website. \n\n" +
                "We embed scripts from these third party service providers: [[ YouTube, Vimeo, Google Maps, Mapbox, Facebook, Twitter, Instagram, ... ]] \n\n" +
                "On activation of one or more of these services personal data might be forwarded to these partners. Further information you find <a href='/datenschutz'>here</a> in our privacy statement.",
            configuration_explanation_headline: "What is a cookie?",
            configuration_explanation_text: "A <strong>cookie</strong> is a small <strong>data file</strong> that is stored when visiting a website on your computer or smartphone. As a result, the stored cookie will be transmitted to the web server each time you visit the website again.",
        },
        "it": {
            explanationText: "Con il vostro consenso, utilizziamo cookie per ottimizzare la visualizzazione. \n\n" +
                "Per l'analisi degli accessi al nostro sito web utilizziamo [[ Google Analytics / Matomo Analytics ]]. \n\n" +
                "Inoltre facciamo uso anche di script di [[ YouTube, Vimeo, Google Maps, Mapbox, Facebook, Twitter, Instagram, ...]]. \n\n " +
                "È possibile quindi che dei dati personali vengano trasmessi a tali offerenti. <a href='/datenschutz'>Cliccate quix</a> per ricevere informazioni sulla protezione dei dati.",
            configuration_explanation_headline: "What is a cookie?",
            configuration_explanation_text: "A <strong>cookie</strong> is a small <strong>data file</strong> that is stored when visiting a website on your computer or smartphone. As a result, the stored cookie will be transmitted to the web server each time you visit the website again.",
        }
    },
    sets: {
        "de": [
            {
                id: 'mandatory',
                name: 'Notwendige Cookies',
                description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.',
                mandatory: true,
                mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
            }, {
                id: 'jobs',
                name: 'OnApply',
                description: 'Diese Skripte und Cookies sind nötig, um die Stellenanzeigen des Dienstes darzustellen. Durch das Aktivieren können personenbezogene Daten an OnApply übertragen werden.',
				accept_with_preset: true,
			}, {
                id: 'mapbox_maps',
                name: 'Mapbox',
                description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes darzustellen. Durch das Aktivieren können personenbezogene Daten an Mapbox übertragen werden.',
				accept_with_preset: true,
			}, {
				id: 'youtube_video',
				name: 'YouTube',
				description: 'Diese Skripte und Cookies sind nötig, um YouTube Videos auf dieser Seite abzuspielen. Durch das Aktivieren können personenbezogene Daten an YouTube übertragen werden.',
				accept_with_preset: true,
			}
        ],
        "en": [
            {
                id: 'mandatory',
                name: 'Essential cookies',
                description: 'Cookies that ensure the functionality and the design of our webpages. No personal data are collected. These cookies are required to save your session settings.',
                mandatory: true,
                mandatory_text: "These cookies are mandatory.",
				accept_with_preset: true,
            }, {
                id: 'jobs',
                name: 'OnApply',
                description: 'Diese Skripte und Cookies sind nötig, um die Stellenanzeigen des Dienstes darzustellen. Durch das Aktivieren können personenbezogene Daten an OnApply übertragen werden.',
				accept_with_preset: true,
			}, {
                id: 'mapbox_maps',
                name: 'Mapbox',
                description: 'Scripts and cookies that are required to display the maps of this service. On activation personal data might be forwarded to Mapbox.',
				accept_with_preset: true,
			}, {
				id: 'youtube_video',
				name: 'YouTube Video',
				description: 'Scripts and cookies that are required to play videos of this service on this website. On activation personal data might be forwarded to YouTube (Google).',
				accept_with_preset: true,
			}
		]
    }
}

export function initCookieNotice() {
    window.myVBCN = init(vbcnOptions);
}
