import {hasClass, addClass, removeClass, slideDown, slideUp} from "./helpers";

const eventTabs = document.querySelectorAll('[data-el="eventtatbs"]');

export function initEventTabs() {

    eventTabs.forEach(function (tabs) {

        let triggers = tabs.querySelectorAll('li');
        let content = tabs.nextElementSibling.querySelectorAll('[data-content]');

        triggers.forEach(function (trigger, superId) {

            trigger.addEventListener('click', function() {
                if(!hasClass(this, 'is-active')) {

                    triggers.forEach(function (trg, triggerId) {
                        removeClass(trg, 'is-active');
                    })
                    addClass(this, 'is-active');

                    content.forEach(function (cnt, contentId) {
                        console.log(contentId);
                        if(superId === contentId) {
                            slideDown(cnt);
                        } else {
                            slideUp(cnt)
                        }
                    })

                }
            })

        })

    })

}
