import {debounce, addStyle} from "./helpers";

const htmlDom = document.querySelector('html');
const bodyDom = document.querySelector('body');
const scrollerEl = document.querySelector('[data-el="scroller"]');
const headerBg = document.querySelector('.header-bg');
const searchClear = document.querySelector('.searchel-clear');

export function initHeroScrollEffect() {

    function heroScrollEffect() {
        document.body.style.setProperty("--hero-scale", 1 + (window.scrollY * 0.0005));
        document.body.style.setProperty("--hero-scroll", window.scrollY / 4 + 'px');
    }

    var heroScrollEffectDebounced = debounce(heroScrollEffect);
    window.addEventListener('scroll', heroScrollEffectDebounced);

}

export function initScrollingProgress() {

    if(scrollerEl) {
        let scrollerProgress = scrollerEl.querySelector('.scroller-circle');

        function calcProgress() {
            let scrollTop = window.scrollY;
            let docHeight = document.body.offsetHeight;
            let winHeight = window.innerHeight;

            let scrollPercent = scrollTop / (docHeight - winHeight);
            let degrees = Math.round(scrollPercent * 264);

            scrollerEl.dataset.percent = Math.round(degrees);
            scrollerProgress.setAttribute('stroke-dasharray', degrees + ' ' + (264 - degrees));
        }

        var calcProgressDebounced = debounce(calcProgress);
        window.addEventListener('scroll', calcProgressDebounced);
    }

}

function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth + 'px';
}

export function setMargins() {
    var GSW = getScrollbarWidth();

    addStyle(htmlDom, 'overflow-y', 'hidden');
    addStyle(htmlDom, 'margin-left', 'calc(100vw - 100%)');
    addStyle(htmlDom, 'margin-right', GSW);
    addStyle(headerBg, 'right', GSW);

    if(window.matchMedia('(max-width: 1800px)').matches) {
        addStyle(bodyDom, 'background-color', '#fff');
    }

    addStyle(document.querySelector('header.header'), 'margin-right', GSW);
    addStyle(document.querySelector('.scroller'), 'margin-right', GSW);
    addStyle(document.querySelector('.sidebarel'), 'margin-right', GSW);
    addStyle(document.querySelector('.searchel'), 'padding-right', GSW);
    addStyle(document.querySelector('.vbcn'), 'margin-right', GSW);
}

export function unsetMargins() {
    addStyle(htmlDom, 'overflow-y', 'scroll');
    addStyle(htmlDom, 'margin-left', 'auto');
    addStyle(htmlDom, 'margin-right', 'auto');
    addStyle(headerBg, 'right', '0');

    addStyle(bodyDom, 'background-color', '#e1e8ec');

    addStyle(document.querySelector('header.header'), 'margin-right', '0');
    addStyle(document.querySelector('.scroller'), 'margin-right', '0');
    addStyle(document.querySelector('.sidebarel'), 'margin-right', '0');
    addStyle(document.querySelector('.searchel'), 'padding-right', '0');
    addStyle(document.querySelector('.vbcn'), 'margin-right', '0');
}

export function bindScrollToErrors() {
	let links = document.querySelector('#form-has-errors');

	if (links) {
		links.scrollIntoView();
	}
}

export function checkMargins() {
    if(menuTrigger.hasClass('is-clicked')) {
        window.matchMedia('(max-width: 992px)').matches ? setMargins() : unsetMargins();
    }
}

export function initCharacterCounter() {

    var textareaFields = document.querySelectorAll('textarea');

    textareaFields.forEach(function(field) {
        let max = field.maxLength;
        if(max > 0) {

            field.addEventListener('change', function() {
                let chars = field.value.length;
                field.nextElementSibling.innerHTML = chars + ' / ' + max;

            })

            field.addEventListener('keyup', function() {
                let chars = field.value.length;
                field.nextElementSibling.innerHTML = chars + ' / ' + max;

            })

        }
    })

}

export function initContactEkmWomensclinic() {
	let formCheckSelector = document.querySelector('.form-js-check');

	if(formCheckSelector) {
		let topicSelect = document.querySelector("#topic_select");
		let dysplasieTopicSelect = document.querySelector(".dysplasie-topic-select");
		let referral = document.querySelector("#referral");
		let referralContent = document.querySelector(".referral-content");
		let topicPregnancy = document.querySelector(".zweite-meinung-topic");
		let dateOfBirth = document.querySelector(".date-of-birth");

		topicSelect.addEventListener("change", (event) => {
			if (event.target.value == 'dysplasie') {
				dysplasieTopicSelect.classList.remove('hidden');
				dateOfBirth.classList.add('hidden');
				topicPregnancy.classList.add('hidden');
			} else if (event.target.value == 'doppler') {
				dateOfBirth.classList.remove('hidden');
				dysplasieTopicSelect.classList.add('hidden');
				topicPregnancy.classList.add('hidden');
			} else if (event.target.value == 'zweite_meinung') {
				topicPregnancy.classList.remove('hidden');
				dateOfBirth.classList.add('hidden');
				dysplasieTopicSelect.classList.add('hidden');
			} else {
				dysplasieTopicSelect.classList.add('hidden');
				dateOfBirth.classList.add('hidden');
				topicPregnancy.classList.add('hidden');
			}
		});

		// topicPregnancy.addEventListener("change", (event) => {
		// 	if (event.target.checked != true) {
		// 		dateOfBirth.classList.add('hidden');
		// 	} else {
		// 		dateOfBirth.classList.remove('hidden');
		// 	}
		// });

		referral.addEventListener("change", (event) => {
			if (event.target.checked != true) {
				referralContent.classList.add('hidden');
			} else {
				referralContent.classList.remove('hidden');
			}
		});
	}
}

export function initSearchClear() {
	if(searchClear) {
		let inputField = searchClear.previousElementSibling;
		searchClear.style.display = inputField.value.length > 0 ? 'block' : 'none';
		inputField.addEventListener('keyup', e => searchClear.style.display = inputField.value.length > 0 ? 'block' : 'none')
		searchClear.addEventListener('click', e => {
			searchClear.style.display = 'none';
			searchClear.previousElementSibling.value = '';
			inputField.focus();
		})

		window.setTimeout(() => {
			inputField.focus();
			let val = inputField.value;
			inputField.value = '';
			inputField.value = val;
		}, 500);
	}
}

