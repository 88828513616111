import { swiffyslider } from 'swiffy-slider'
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import {addClass, removeClass, hasClass} from "./helpers";

const certificateSliders = document.querySelectorAll('[data-slider="certificates"]')
const verticalSliders = document.querySelectorAll('[data-slider="vertical"]')
const horizontalSliders = document.querySelectorAll('[data-slider="horizontal"]')

export function initSwiffySliders() {
	window.swiffyslider = swiffyslider;

	window.addEventListener("load", () => {
		window.swiffyslider.init();
	});
}

export function initCertificateSliders() {

	certificateSliders.forEach(function(slider) {

        let wrapper = slider.querySelector('.swiper-wrapper');
        if(wrapper.childElementCount > 1) {

            let swiper = new Swiper(slider, {
                modules: [Navigation, Autoplay],
                spaceBetween: 32,
                slidesPerView: 1,
                loop: false,
                autoHeight: false,
				navigation: {
					nextEl: slider.parentNode.parentNode.querySelector('.swiper-button-next'),
					prevEl: slider.parentNode.parentNode.querySelector('.swiper-button-prev'),
				},
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
					pauseOnMouseEnter: true
                },
                breakpoints: {
                    576: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    992: {
                        slidesPerView: 4,
                        spaceBetween: 24
                    },
                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 32
                    },
                },
                on: {
                    init: function () {
                        this.virtualSize > this.size ? removeClass(this.el, 'is-centered') : addClass(this.el, 'is-centered');
                    },
                    slideChange: function () {
                        this.virtualSize > this.size ? removeClass(this.el, 'is-centered') : addClass(this.el, 'is-centered');
                    },
                    resize: function () {
                        this.virtualSize > this.size ? removeClass(this.el, 'is-centered') : addClass(this.el, 'is-centered');
                    },
                }
            });

        }

    })

}

export function initverticalSliders() {

	verticalSliders.forEach(function(slider) {

		let wrapper = slider.querySelector('.swiper-wrapper');
		if(wrapper.childElementCount > 1) {

			let swiper = new Swiper(slider, {
				modules: [Navigation, Pagination],
				spaceBetween: 0,
				slidesPerView: 1,
				loop: true,
				autoHeight: false,
				navigation: {
					nextEl: slider.parentNode.querySelector('.swiper-button-next'),
					prevEl: slider.parentNode.querySelector('.swiper-button-prev'),
				},
				pagination: {
					el: slider.parentNode.querySelector('.swiper-pagination'),
					type: 'bullets',
				},
				autoplay: false,
			});

		}
	})

}

export function initHorizontalSliders() {

	horizontalSliders.forEach(function(slider) {

		let wrapper = slider.querySelector('.swiper-wrapper');
		if(wrapper.childElementCount > 1) {

			let swiper = new Swiper(slider, {
				modules: [Navigation, Pagination],
				spaceBetween: 0,
				slidesPerView: 1,
				loop: true,
				autoHeight: false,
				navigation: {
					nextEl: slider.parentNode.querySelector('.swiper-button-next'),
					prevEl: slider.parentNode.querySelector('.swiper-button-prev'),
				},
				pagination: {
					el: slider.parentNode.querySelector('.swiper-pagination'),
					type: 'bullets',
				},
				autoplay: false,
			});

		}
	})

}
