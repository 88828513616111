"use strict";

export function metalang() {
    let metalang = document.querySelector('html[lang]') ? document.querySelector('html').getAttribute('lang').toLowerCase() : false;
    if (!metalang || metalang.trim() === "") {
        return 'de'
    }
    return metalang;
}

export var lang = metalang();


export var mobilesettings = {
    Utils : {
        PHONE_TAG : "mobile",
        PHABLET_TAG : "phablet",
        TABLET_TAG : "tablet",
        DESKTOP_TAG : "desktop",
        DESKTOP_LARGE_TAG : "wide",
        DESKTOP_ULTRAWIDE_TAG : "ultrawide",
    }
};

// Track breakpoints in JS
export function getResponsiveTag () {
    var tag = window.getComputedStyle(document.body,':after').getPropertyValue('content');
    tag = tag.replace( /"|'/g,'');   // Firefox bugfix
    return tag;
};

export function isMobile () {
    return getResponsiveTag() === mobilesettings.Utils.PHONE_TAG || getResponsiveTag() === mobilesettings.Utils.PHABLET_TAG;
};

export function isTablet () {
    return getResponsiveTag() === mobilesettings.Utils.TABLET_TAG;
};

export function nodeListToArray (nodelist) {
    return [].slice.call(nodelist);
}

export function tableFlip () {
    console.log('(╯°□°）╯︵ ┻━┻');
};

export function  randomIntFromInterval (min,max) {
    return Math.floor(Math.random()*(max-min+1)+min);
};

export function getLayout (layoutContainer) {
    var layout = document.querySelector(layoutContainer).length > 0 ?  document.querySelector(layoutContainer).data('layout') : false;
    return layout;
};

export function getScreenWidth () {
    return window.innerWidth;
};

export function getBaseUrl() {
    var baseurl;
    if (document.querySelector('base')) {
        baseurl = document.querySelector('base').prop('href');
    } else {
        if (window.location.protocol != "https:") {
            baseurl = 'http://' + window.location.hostname;
        } else {
            baseurl = 'https://' + window.location.hostname;
        }
    }
    return baseurl;
}

export function findAncestorByClass (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}

export function addClass(el, cls) {
    if (!el || el.classList.contains(cls)) {
        return
    }
    el.classList.add(cls);
}

export function removeClass(el, cls) {
    if (el && el.classList.contains(cls)) {
        el.classList.remove(cls);
    }
}

export function hasClass (el, cls) {
    if (!el) return
    return el.classList.contains(cls)
}

export function toggleClass(el, cls) {
    if (el.classList.contains(cls)) {
        removeClass(el, cls)
    } else {
        addClass(el, cls)
    }
}

export function addStyle(el, styleKey, styleValue) {
    if(el) {
        let elStyles = el.style;
        elStyles[styleKey] = styleValue;
    }
}

export function wrapElement(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

export function getWindowScrollPosition() {
    return {
        x: window.pageXOffset || document.documentElement.scrollLeft,
        y: window.pageYOffset || document.documentElement.scrollTop
    };
}

export function scrollTo(element, to, duration) {
    if (duration <= 0) return;
    let difference = to - element.scrollTop;
    let perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

export function encode_utf8(s) {
    return unescape(encodeURIComponent(s));
}

export function decode_utf8(s) {
    return decodeURIComponent(escape(s));
}
/**
 * Fixes SVGs attributes to include the window.location in case a base tag is present
 */
export function fixSVG () {
    /**
     * Current URL, without the hash
     */
    var baseUrl = window.location.href.replace(window.location.hash, "");

    /**
     *  Find all `use` elements with a namespaced `href` attribute, e.g.
     *  <use xlink:href="#some-id"></use>
     *
     *  See: http://stackoverflow.com/a/23047888/796152
     */
    [].slice.call(document.querySelectorAll("use[*|href]"))

        /**
         * Filter out all elements whose namespaced `href` attribute doesn't
         * start with `#` (i.e. all non-relative IRI's)
         *
         * Note: we're assuming the `xlink` prefix for the XLink namespace!
         */
        .filter(function(element) {
            return (element.getAttribute("xlink:href").indexOf("#") === 0);
        })

        /**
         * Prepend `window.location` to the namespaced `href` attribute value,
         * in order to make it an absolute IRI
         *
         * Note: we're assuming the `xlink` prefix for the XLink namespace!
         */
        .forEach(function(element) {
            element.setAttribute("xlink:href", baseUrl + element.getAttribute("xlink:href"));
        });

    /** Clip-path attributes */
    [].slice.call(document.querySelectorAll("svg [clip-path]"))
        .filter(function(element) {
            return (element.getAttribute("clip-path").indexOf("url(#") === 0);
        })
        .forEach(function(element) {
            let oldVal = element.getAttribute("clip-path").slice(4,-1);
            let newVal = "url(" + baseUrl + oldVal +  ")";
            element.setAttribute("clip-path", newVal);
        });

    /** Mask attributes */
    [].slice.call(document.querySelectorAll("svg [mask]"))
        .filter(function(element) {
            return (element.getAttribute("mask").indexOf("url(#") === 0);
        })
        .forEach(function(element) {
            let oldVal = element.getAttribute("mask").slice(4,-1);
            let newVal = "url(" + baseUrl + oldVal +  ")";
            element.setAttribute("mask", newVal);
        });

    /** Pattern attributes */
    [].slice.call(document.querySelectorAll("svg [pattern]"))
        .filter(function(element) {
            return (element.getAttribute("pattern").indexOf("url(#") === 0);
        })
        .forEach(function(element) {
            let oldVal = element.getAttribute("pattern").slice(4,-1);
            let newVal = "url(" + baseUrl + oldVal +  ")";
            element.setAttribute("pattern", newVal);
        });

    /** Fill attributes */
    [].slice.call(document.querySelectorAll("svg [fill]"))
        .filter(function(element) {
            return (element.getAttribute("fill").indexOf("url(#") === 0);
        })
        .forEach(function(element) {
            let oldVal = element.getAttribute("fill").slice(4,-1);
            let newVal = "url(" + baseUrl + oldVal +  ")";
            element.setAttribute("fill", newVal);
        });
}

export function fadeIn(el, display) {
    if(el) {
        el.style.opacity = 0;
        el.style.display = display || 'block';
        (function fade() {
            var val = parseFloat(el.style.opacity);
            if (!((val += .1) > 1)) {
                el.style.opacity = val;
                requestAnimationFrame(fade);
            }
        })();
    }
}

export function fadeOut(el) {
    if(el) {
        el.style.opacity = 1;
        (function fade() {
            if ((el.style.opacity -= .1) < 0) {
                el.style.display = 'none';
            } else {
                requestAnimationFrame(fade);
            }
        })();
    }
}

export function fadeToggle(el) {
    if(el) {
        if (window.getComputedStyle(el).display === 'none') {
            return fadeIn(el);
        } else {
            return fadeOut(el);
        }
    }
}

export function slideUp(el, duration= 500) {
    el.style.transitionProperty = 'height, margin, padding';
    el.style.transitionDuration = duration + 'ms';
    el.style.boxSizing = 'border-box';
    el.style.height = el.offsetHeight + 'px';
    el.offsetHeight;
    el.style.overflow = 'hidden';
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
    window.setTimeout( () => {
        el.style.display = 'none';
        el.style.removeProperty('height');
        el.style.removeProperty('padding-top');
        el.style.removeProperty('padding-bottom');
        el.style.removeProperty('margin-top');
        el.style.removeProperty('margin-bottom');
        el.style.removeProperty('overflow');
        el.style.removeProperty('transition-duration');
        el.style.removeProperty('transition-property');
    }, duration);
}

export function slideDown(el, duration= 500) {
    el.style.removeProperty('display');
    let display = window.getComputedStyle(el).display;
    if (display === 'none') display = 'block';
    el.style.display = display;
    let height = el.offsetHeight;
    el.style.overflow = 'hidden';
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
    el.offsetHeight;
    el.style.boxSizing = 'border-box';
    el.style.transitionProperty = 'height, margin, padding';
    el.style.transitionDuration = duration + 'ms';
    el.style.height = height + 'px';
    el.style.removeProperty('padding-top');
    el.style.removeProperty('padding-bottom');
    el.style.removeProperty('margin-top');
    el.style.removeProperty('margin-bottom');
    window.setTimeout( () => {
        el.style.removeProperty('height');
        el.style.removeProperty('overflow');
        el.style.removeProperty('transition-duration');
        el.style.removeProperty('transition-property');
    }, duration);
}

export function slideToggle(el, duration = 500) {
    if (window.getComputedStyle(el).display === 'none') {
        return slideDown(el, duration);
    } else {
        return slideUp(el, duration);
    }
}

export function debounce(fn) {
    var timeout;
    return function () {
        var context = this;
        var args = arguments;
        if (timeout) {
            window.cancelAnimationFrame(timeout);
        }
        timeout = window.requestAnimationFrame(function () {
            fn.apply(context, args);
        });
    }
}

export function getParentsUntil(elem, filter) {
    let parents = [];
    let parent = elem.parentNode;
    let index = 0;
    if (filter && typeof filter !== 'function') { filter = null; }
    while (parent && parent !== document) {
        if (stop) {
            if (stop(parent, index, elem)) break;
        }
        if (filter) {
            if (filter(parent, index, elem)) {
                parents.push(parent);
            }
        } else {
            parents.push(parent);
        }
        index++;
        parent = parent.parentNode;
    }
    return parents;
}

export function getNextUntil (elem, callback) {
    let siblings = [];
    let next = elem.nextElementSibling;
    let index = 0;
    while (next) {
        if (callback && typeof callback === 'function' && callback(next, index, elem)) break;
        siblings.push(next);
        index++;
        next = next.nextElementSibling;
    }
    return siblings;
}

export function getSiblings(el) {
	let descendants = el.parentNode.children;
	return Array.prototype.filter.call(descendants,  sibling => {
		return sibling !== el;
	});
}
