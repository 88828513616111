import {fadeIn, fadeOut} from "./helpers";

const socialsTriggers = document.querySelectorAll('[data-socials="trigger"]');
const socialsWindow = document.querySelector('[data-socials="window"]');

export function initSocials() {

	if(socialsWindow) {
		let socialsClose = socialsWindow.querySelector('[data-socials="close"]');

		socialsTriggers.forEach(trigger => {
			trigger.addEventListener('click', e => {
				fadeIn(socialsWindow);
			})
		})

		socialsClose.addEventListener('click', e => {
			fadeOut(socialsWindow);
		})
	}

}
