import {addClass, addStyle, hasClass, removeClass} from "./helpers";

const contactsInfo = document.querySelectorAll('[data-contacts="info"]');

function checkDesiredPosition(elm, alm) {
    let desiredPosition;
    if (window.matchMedia("(min-width: 993px)").matches) {
        let add = elm % 3 === 0 ? 0 : elm % 3 === 1 ? 2 : 1;
        if(elm >= alm.length) {
            desiredPosition = +alm.length;
        } else {
            if((+elm + add) >= +alm.length) {
                desiredPosition = +alm.length;
            } else {
                desiredPosition = +elm + add;
            }
        }
    } else if (window.matchMedia("(min-width: 577px)").matches) {
        let add = elm % 2 === 0 ? 0 : 1;
        desiredPosition = elm >= alm.length ? +alm.length : +elm + add;
    } else {
        desiredPosition = +elm;
    }

    return desiredPosition;
}

function checkDesiredPositionOfArrow(elm) {
    let desiredPositionOfArrow;
    if (window.matchMedia("(min-width: 993px)").matches) {
        if(elm % 3 === 0) {
            desiredPositionOfArrow = 'right';
        } else if(elm % 3 === 1) {
            desiredPositionOfArrow = 'left';
        } else {
            desiredPositionOfArrow = 'center';
        }
    } else if (window.matchMedia("(min-width: 577px)").matches) {
        desiredPositionOfArrow = elm % 2 === 0 ? 'right' : 'left';
    } else {
        desiredPositionOfArrow = 'center';
    }

    return desiredPositionOfArrow;
}

export function initContactInfos() {

	contactsInfo.forEach(function(list) {

        let triggers = list.querySelectorAll('.contactinfo-item');
        triggers.forEach(function(trigger) {
            trigger.addEventListener('click', function(e) {
				if(e.target.closest('.contactinfo-icons')) {

					let triggerId = this.dataset.trigger;
					let triggerContent = list.nextElementSibling.querySelector('#person-' + triggerId);

					if(triggerContent) {

						// Scroll to clicked element
						let clickedOne = this;
						setTimeout(function () {
							window.scrollTo({
								top: clickedOne.offsetTop - 80,
								behavior: "smooth"
							});
							// clickedOne.scrollIntoView();
						}, 350);

						// Remove previously apeneded <template>
						let oldOne = list.querySelector('.contactinfo-info');
						if(oldOne) {
							oldOne.parentNode.removeChild(oldOne);
						}

						if(hasClass(clickedOne, 'is-clicked')) {
							// Remove 'is-clicked' class
							removeClass(clickedOne, 'is-clicked');
						} else {
							// Get needed <template> and its content
							let cloned = triggerContent.content.cloneNode(true);
							triggers[checkDesiredPosition(triggerId, triggers) - 1].after(cloned);

							// Create SlideDown for appended <template>
							let appended = list.querySelector('.contactinfo-info');
							// Add class for arrow
							addClass(appended, 'arrow-' + checkDesiredPositionOfArrow(triggerId));
							// Increase height from 0 to needed
							addStyle(appended, 'height', appended.scrollHeight + 'px');
							// Set auto height after transition ends
							appended.addEventListener('transitionend', function(e) {
								if(e.propertyName === 'height') {
									addStyle(appended, 'height', 'auto');
								}
							})

							// Set 'is-clicked' class
							triggers.forEach(function (trg, id) {
								(+id + 1) === +triggerId ? addClass(trg, 'is-clicked') : removeClass(trg, 'is-clicked');
							})
						}

					}

				}
            })

            window.addEventListener('resize', function() {
                let infos = document.querySelectorAll('.contactinfo-info');
                infos.forEach(function(info) {
                    info.parentNode.removeChild(info);
                })

                triggers.forEach(function(trigger) {
                    removeClass(trigger, 'is-clicked');
                })
            })
        })
    })

}
