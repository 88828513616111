import {fadeIn, fadeOut} from "./helpers";

const welcomeInfo = document.querySelector('.welcomeinfo');

export function initWelcomeInfo() {

	if(!welcomeInfo) return;

	if (localStorage.getItem('welecomeinfo') === null) {
		welcomeInfo.style.display = 'block';

		const toClose = welcomeInfo.querySelector('.welcomeinfo-button');
		toClose.addEventListener('click', e => {
			fadeOut(welcomeInfo);
			localStorage.setItem('welecomeinfo', 'true');
		})

		// document.body.addEventListener('click', e => {
		// 	if(e.target.closest('.welcomeinfo-content')) {
		// 		fadeOut(welcomeInfo);
		// 	}
		// })
	}


}
