import {addClass, hasClass, removeClass, slideDown, slideUp} from "./helpers";

const list = document.querySelector('[data-el="contactslist"]');

function getActiveTags() {
	return list.querySelectorAll('.contactslist-tags-list li.active');
}

function getHiddenItems() {
	return list.querySelectorAll('.contactslist li.is-hidden');
}

export function initContactsList() {

	if(!list) return;

	// const searchForm = list.querySelector('.searchel-form');
	// searchForm.addEventListener('submit', e => {
	// 	e.preventDefault();
	// 	console.log('its submitted');
	// })

	let activeTagsList = [];
	const loadMore = list.querySelector('.contactslist-more');
	const noResults = list.querySelector('.contactslist-empty');
	const tags = list.querySelectorAll('.contactslist-tags-list li');
	const items = list.querySelectorAll('.contactslist li');

	tags.forEach(tag => {
		tag.addEventListener('click', e => {

			activeTagsList = []

			// Manipulate coloring
			if(tag.dataset.tag === 'all') {
				tags.forEach(tag => tag.dataset.tag === 'all' ? addClass(tag, 'active') : removeClass(tag, 'active'))
			} else {
				if(hasClass(tag, 'active')) {
					removeClass(tag, 'active');
					if(getActiveTags().length === 0) tags.forEach(tag => tag.dataset.tag === 'all' ? addClass(tag, 'active') : '')
				} else {
					addClass(tag, 'active');
					let clicked = tag;
					tags.forEach(tag => tag === clicked ? addClass(tag, 'active') : removeClass(tag, 'active'))
					// tags.forEach(tag => tag.dataset.tag === 'all' ? removeClass(tag, 'active') : '')
				}
			}

			getActiveTags().forEach(tag => {
				if(tag.dataset.tag !== 'all') activeTagsList.push(tag.dataset.tag);
			});

			let matchItems = activeTagsList.length === 0 ? items : Array.from(items).filter(el => activeTagsList.includes(el.dataset.tag));
			matchItems.length > 20 ? removeClass(loadMore, 'is-hidden') : addClass(loadMore, 'is-hidden');

			items.forEach(item => {
				addClass(item, 'is-hidden');
			})

			matchItems.length === 0 ? removeClass(noResults, 'is-hidden') : addClass(noResults, 'is-hidden');

			matchItems.forEach((item, i) => {
				if(i < 20) {
					removeClass(item, 'is-hidden');
				} else {
					addClass(item, 'is-hidden');
				}
			})

		})
	})

	loadMore.addEventListener('click', e => {
		let hiddenItems;
		let tag = list.querySelector('.contactslist-tags-list li.active').dataset.tag;

		if(tag === 'all') {
			hiddenItems = list.querySelectorAll('.contactslist li.is-hidden');
		} else {
			hiddenItems = list.querySelectorAll('.contactslist li.is-hidden[data-tag="' + tag + '"]');
		}
		if(hiddenItems.length <= 20) addClass(loadMore, 'is-hidden');
		hiddenItems.forEach((item, i) => {
			if(i < 20) {
				// addClass(item, 'is-visible');
				removeClass(item, 'is-hidden');
			}
		})
	})


}
