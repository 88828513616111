import $ from 'jquery';
import magnificPopup from "magnific-popup";

function lightBoxTranslation() {

    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'X (Esc)',
        tLoading: '...',
        gallery: {
            tPrev: '&#8592;',
            tNext: '&#8594;',
            tCounter: '%curr% / %total%'
        },
        image: {tError: 'x-x'},
        ajax: {tError: 'x-x'}
    });

}

export function embedVideoLightbox() {

    lightBoxTranslation();

    let ytVideos = document.querySelectorAll('.embed_video-object');
    ytVideos.forEach(function(video) {
        let poster = video.querySelector('.embed_video-poster');
        if(poster) {
            poster.addEventListener('click', function() {
                if (window.matchMedia("(max-width: 768px)").matches) {
                    $.magnificPopup.open({
                        type: 'iframe',
                        items: {src: this.nextElementSibling.dataset.watch}
                    });
                } else {
                    let iFrame = poster.nextElementSibling;
                    iFrame.style.display = 'block';
                    iFrame.src = iFrame.src + '?autoplay=1';
                    poster.style.display = 'none';
                }
            })
        } else {
            let iFrame = poster.nextElementSibling;
            iFrame.style.display = 'block';
            iFrame.src = iFrame.dataset.src + '?autoplay=1';
        }
    })
}

export function singleImageLightbox() {

    lightBoxTranslation();

    const singleImages = document.querySelectorAll('[data-trigger="image"]');
    singleImages.forEach(function(sImage) {
        sImage.addEventListener('click', function() {

            let itemsCollection = this.nextElementSibling.innerHTML;
            $.magnificPopup.open({
                type: 'image',
                gallery: {enabled: true},
                items: JSON.parse(itemsCollection)
            });
        })
    })

}

export function galleryLightbox() {

    if ($('.gallery-list').length) {
        $('.gallery-list').each(function () {
            $(this).magnificPopup({
                delegate: '.gallery-list-link',
                type: 'image',
                gallery: {enabled: true},
                image: {
                    titleSrc: function (item) {
                        if (item.el.find('.gallery-meta').html() === undefined) {
                            return '<small></small>';
                        } else {
                            return item.el.find('.gallery-meta').html();
                        }
                    }
                }
            });
        });
    }

}
